import GoogleLoginButton from "./GoogleLoginButton";
import { getEmail } from "../authSlice";
import { useState } from "react";
import { useDispatch } from "react-redux";

export const LoginPopUp = () => {
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();

  const handleLogin = async () => {
    await dispatch(getEmail(email));
  };

  return (
    <div className="w-full h-[60vh] bg-white rounded-t-3xl md:w-[40vw] md:h-[50vh] shadow-lg flex flex-col mx-auto">
      <div className="py-6 px-6 flex flex-col gap-6">
        {/* Header */}
        <div>
          <p className="text-lg font-semibold">Login / Signup</p>
          <p className="text-gray-500 text-sm">
            Join us now to be a part of the Shamaim family.
          </p>
        </div>

        {/* Input and Button */}
        <div className="flex flex-col gap-4 mt-4">
          <input
            type="email"
            value={email}
            placeholder="Enter your email address"
            className="h-12 rounded-md border border-gray-300 px-4 focus:outline-none focus:ring-2 focus:ring-blue-400"
            onChange={(e) => setEmail(e.target.value)}
          />
          <button
            className="w-full h-12 rounded-md bg-[#E3E5E9] text-black font-semibold hover:bg-gray-300 transition duration-200"
            onClick={handleLogin}
          >
            CONTINUE
          </button>
        </div>

        {/* Or Divider */}
        <p className="text-center text-gray-500 text-sm">or</p>

        {/* Google Login */}
        <div>
          <GoogleLoginButton />
        </div>

        {/* Terms and Conditions */}
        <p className="text-center text-xs text-gray-400">
          By creating an account or logging in, you agree to Shamaim's{" "}
          <span className="text-blue-500 cursor-pointer">Terms and Conditions</span>{" "}
          and{" "}
          <span className="text-blue-500 cursor-pointer">Privacy Policy</span>.
        </p>
      </div>
    </div>
  );
};
