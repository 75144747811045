import { useState, useEffect } from "react";
import { VarifyOtp } from "../authSlice";
import { useDispatch } from "react-redux";

export const OtpVerification = ({ setIsOpen }) => {
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [email, setEmail] = useState("");
  const [isResendDisabled, setIsResendDisabled] = useState(false);
  const [timer, setTimer] = useState(30); // Resend OTP timer in seconds
  const dispatch = useDispatch();

  // Fetch email from localStorage on mount
  useEffect(() => {
    const storedEmail = localStorage.getItem("email") || "your-email@example.com";
    setEmail(storedEmail);
  }, []);

  // Update individual OTP input fields
  const handleOtpChange = (value, index) => {
    if (!/^\d*$/.test(value)) return; // Only allow numeric input
    const newOtp = [...otp];
    newOtp[index] = value.slice(-1); // Keep only the last digit
    setOtp(newOtp);
  };

  // Handle Resend OTP
  const handleResend = () => {
    setIsResendDisabled(true);
    setTimer(30); // Reset the timer
    // Trigger resend logic here
    console.log("Resend OTP triggered");
  };

  // Timer countdown
  useEffect(() => {
    let countdown;
    if (isResendDisabled) {
      countdown = setInterval(() => {
        setTimer((prev) => {
          if (prev === 1) {
            clearInterval(countdown);
            setIsResendDisabled(false);
          }
          return prev - 1;
        });
      }, 1000);
    }
    return () => clearInterval(countdown);
  }, [isResendDisabled]);

  // Handle OTP Verification
  const handleVerification = () => {
    const otpValue = otp.join(""); // Combine array values into a single string
    dispatch(VarifyOtp({ email, otp: otpValue }));
  };

  return (
    <div className="w-[100vw] bg-white h-[50vh] rounded-2xl">
      {/* Title */}
      <p className="px-6 py-5 text-xl font-semibold text-[#000]">OTP Verification</p>

      {/* Info Section */}
      <div className="flex flex-col justify-center items-center py-5 gap-3">
        <div className="flex flex-col justify-center items-center">
          <p className="text-[#8F98A9] font-semibold">
            We have sent a verification code to
          </p>
          <p>
            {email}
          </p>
        </div>

        {/* OTP Input Fields */}
        <div className="flex gap-4">
          {otp.map((digit, index) => (
            <input
              key={index}
              type="text"
              maxLength="1"
              className="w-10 h-14 text-center text-xl border rounded-md"
              value={digit}
              onChange={(e) => handleOtpChange(e.target.value, index)}
            />
          ))}
        </div>

        {/* Resend OTP */}
        <p
          className={`text-[#207BB4] font-semibold ${
            isResendDisabled ? "cursor-not-allowed text-gray-400" : "cursor-pointer"
          }`}
          onClick={!isResendDisabled ? handleResend : null}
        >
          {isResendDisabled ? `Resend OTP in ${timer}s` : "Resend OTP"}
        </p>

        {/* Continue Button */}
        <div className="w-full px-6">
          <button
            className="w-full h-12 rounded-md bg-[#E3E5E9] hover:bg-gray-300"
            onClick={handleVerification}
          >
            CONTINUE
          </button>
        </div>
      </div>
    </div>
  );
};
