import NavBar from '../features/navbar/Navbar';
import UserOrdersDetailspage from '../features/user/components/UserOrderDetailsPage';


function UserOrdersDetails() {
  return (
    <div>
        <UserOrdersDetailspage></UserOrdersDetailspage>
    </div>
  );
}

export default UserOrdersDetails;
