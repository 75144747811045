import React from 'react'
import NavBar from '../features/navbar/Navbar'
import Footer from '../features/common/Footer'
import ContactUs from './LandingPage/Contactus/ContactUs'
const AllProductsPage = () => {
  return (
    <div>
        <ContactUs></ContactUs>
              
    </div>
  )
}

export default AllProductsPage
