import NavBar from '../features/navbar/Navbar';
import{UserProfile} from '../features/user/components/UserProfile';
function UserProfilePage() {
  return (
    <div>
        <UserProfile></UserProfile>

    </div>
  );
}

export default UserProfilePage;