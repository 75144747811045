import NavBar from '../features/navbar/Navbar';
import Footer from '../features/common/Footer';
import AboutUs from './LandingPage/Aboutus/AboutUs';

function AboutUsPage() {
  return (
    <div>

      <AboutUs></AboutUs>
      <Footer></Footer>
    </div>
  );
}

export default AboutUsPage;