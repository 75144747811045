import React from 'react'
import ProductList from '../features/product/components/ProductList'
import NavBar from '../features/navbar/Navbar'
import Footer from '../features/common/Footer'
const AllProductsPage = () => {
  return (
    <div>
        <ProductList></ProductList>
      
    </div>
  )
}

export default AllProductsPage
