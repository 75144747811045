
 import React from 'react'
 import NavBar from '../../../features/navbar/Navbar'
 import Footer from '../../../features/common/Footer'
import { RefundReturn } from './terms&condition'
import { Temscondition } from './terms&conditions'
 
 export default function TermConditionmain() {
   return (
     <div>
       <Temscondition></Temscondition>
     </div>
   )
 }
 