import { useSelector, useDispatch } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { selectLoggedInUser, getOtpResponce, fetchAuthStatus } from "../authSlice";
import { LoginPopUp } from "./loginPop";
import { OtpVerification } from "./otpVerificationPopUp";

export default function Login() {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState("false");

  const otpResponce = useSelector(selectLoggedInUser);
  const data = useSelector(getOtpResponce);

  useEffect(() => {
    dispatch(fetchAuthStatus());
  }, [dispatch, otpResponce]);

  return (
    <>
      {otpResponce && <Navigate to="/" replace={true}></Navigate>}
      <div className="relative h-screen w-screen">
        {/* Background Image */}
        <img
          src="https://firebasestorage.googleapis.com/v0/b/shamaim-lifestyle.appspot.com/o/homepage%20video%2FLanding%20page%20c%201.png?alt=media&token=483abd31-dea2-482a-8c78-d72adbaceb37"
          alt="Landing Page"
          className="absolute top-0 left-0 w-full h-full object-cover"
        />
        
        {/* Overlay Content */}
        <div className="absolute inset-0 flex justify-center items-center p-4">
          {data === "OTP sent successfully" ? (
            <div>
              <OtpVerification setIsOpen={setIsOpen} />
            </div>
          ) : (
            <div className="w-full max-w-md">
              <LoginPopUp />
            </div>
          )}
        </div>
      </div>
    </>
  );
}
