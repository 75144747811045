import Cart from "../features/cart/Cart";
import NavBar from "../features/navbar/Navbar";
import Footer from "../features/common/Footer";
function CartPage() {
    return <div>
    <Cart></Cart>
        
    </div>;
}

export default CartPage;