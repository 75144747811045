
 import React from 'react'
 import NavBar from '../../../features/navbar/Navbar'
 import Footer from '../../../features/common/Footer'
import { RefundReturn } from './terms&condition'
 
 export default function RefundReturnPolicy() {
   return (
     <div>
       <RefundReturn></RefundReturn>
     </div>
   )
 }
 